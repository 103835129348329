.center {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  padding: 0 4%;
}

input,
select,
textarea {
  max-width: rem-calc(1200);
}
