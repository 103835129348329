:root {
  --fourth-color: rgb(58, 54, 219);
  --sixth-color: rgba(230, 236, 244, 0.35);
  --gray-color: rgb(128, 159, 184);
  --color-bg-button-blue: #153b8a;
  --color-bg-yellow: #ffdd00;
  --color-i-text-yellow: #ffd400;
  --color-text-gray: #3c4257;
  --color-bg-blue: #293a75;
  --color-text-white: #ffffff;
  --color-border-bg-blue: #009de1;
  --color-bg-blue-footer: #009de0;
  --color-text-two: #06152b;
  --color-error: #ea3a3d;
  --color-sucess: #1db24b;
  --color-border-input-focus: #0090ff;
  --color-border-input: #d9e1e7;
  --color-bg-input-desable: #f1f4f9;
  --color-text-input-and-bg-all: #809fb8;
  --color-text-input-find: #99b2c6;
  --color-text-three: #1A1F36;
  --color-border-b-footer:#ffffff80;
  --color-text-lei:#153B8A;
  --color-text-password:#C0BDCC;
  --color-button-home: #002d78;
  --color-bg-gray: #e7edf5;
  --color-bg-input: #d9e1e766;
  --color-bg-input-search: #ecf2f8;
  --color-text-label: #9aa8a0;
  --color-bg-filter: #ededed;
  --color-text-ul-li: #6a6a6a;
  --color-border-checkbox: #707070;
  --color-label-select: #05b44f;
  --color-bg-attencion: #ea3a3d;
  --color-border-dash: #868080;
  --color-bg-blue:#00b5f1;
  --color-input-value: #F5F5F5;
  --color-div-empty: ##F4F4F4;
}
