@import "./app/shared/config-css/reset.scss";
@import "./app/shared/config-css/variavel-global.scss";
@import "./app/shared/config-css/rem-calc.scss";
@import "./app/shared/config-css/layout-style.scss";

// Font import

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

.ant-collapse>.ant-collapse-item {
  background-color: var(--color-text-white);
  border-bottom: none !important;
  margin-bottom: rem-calc(30) !important;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse-header {
  color: var(--color-text-gray) !important;
  font-size: rem-calc(16);
  font-weight: 500;
}


.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  margin-top: rem-calc(30) !important;

  &:active,
  &:visited,
  &:hover,
  &:focus-visible {
    font-weight: 600 !important;
    color: var(--color-border-bg-blue) !important;
  }
}

.ant-collapse-content>.ant-collapse-content-box>.ng-tns-c60-0 {
  color: var(--color-text-gray) !important;
  font-weight: 500 !important;
  font-size: rem-calc(14) !important;
  line-height: 2 !important;
}

.modal.show .modal-dialog {
  transform: none;
  height: 100%;
  max-width: 82%;
}

.modal-header .btn-close {
  border: rem-calc(2) solid;
  border-radius: 100%;
}


.ant-table-content {
  .ant-table-thead>tr>th {
    background-color: var(--color-border-bg-blue);
    color: var(--color-text-white);
    text-align: center;
  }
}

.ant-steps-item-container {
  .ant-steps-item-icon {
    border: rem-calc(1) dashed var(--color-border-checkbox) !important;
    font-size: rem-calc(16);
    font-weight: bold;
  }
}

.ant-steps {
  width: 80%;
  font-size: 0;
  text-align: center;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background-color: var(--color-bg-button-blue);
}

.ant-table-cell {

}

.ant-table-selection-column {
  width: 5%;
}


.ant-table-tbody>tr>td{
  line-height: rem-calc(6);
}

.ant-table tfoot>tr>td, .ant-table tfoot>tr>th, .ant-table-tbody>tr>td, .ant-table-thead>tr>th{
  padding: rem-calc(17) 0 rem-calc(13)  rem-calc(3);

}
